<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loadingState">
                <b-row class="bg-success text-white text-center mb-3">
                    <h4 class="text-center w-50 m-auto text-white">{{$t('research_manage.general_info')}}</h4>
                </b-row>
              <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                    <b-row>
                    <b-col lg="6" sm="12">
                          <ValidationProvider name="Name (En)" vid="name_en" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="name_en"
                              slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('research_manage.name_en')}}<span class="text-danger"> *</span>
                              </template>
                              <b-form-input
                              id="name_en"
                              v-model="subject_expert.name_en"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                          <ValidationProvider name="Name (Bn)" vid="name_bn" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="name_bn"
                              slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('research_manage.name_bn')}}<span class="text-danger"> *</span>
                              </template>
                              <b-form-input
                              id="name_bn"
                              v-model="subject_expert.name_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Designation (En)" vid="designation_en" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="designation_en"
                              slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('research_manage.designation_en')}} <span class="text-danger"> *</span>
                              </template>
                              <b-form-input
                              id="designation_en"
                              v-model="subject_expert.designation_en"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Designation (Bn)" vid="designation_bn" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="designation_bn"
                              slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('research_manage.designation_bn')}} <span class="text-danger"> *</span>
                              </template>
                              <b-form-input
                              id="designation_bn"
                              v-model="subject_expert.designation_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Organization name (En)" vid="org_name_en" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="org_name_en"
                              slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('research_manage.org_name_en')}}<span class="text-danger"> *</span>
                              </template>
                              <b-form-input
                              id="org_name_en"
                              v-model="subject_expert.org_name_en"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Organization Name(Bn)" vid="org_name_bn" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="org_name_bn"
                              slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('research_manage.org_name_bn')}} <span class="text-danger"> *</span>
                              </template>
                              <b-form-input
                              id="org_name_bn"
                              v-model="subject_expert.org_name_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Mobile" vid="mobile" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="mobile"
                              slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('research_manage.mobile')}}<span class="text-danger"> *</span>
                              </template>
                              <b-form-input
                              id="mobile"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                              v-model="subject_expert.mobile"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Email" vid="email" rules="required|email">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="email"
                              slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('research_manage.email')}}<span class="text-danger"> *</span>
                              </template>
                              <b-form-input
                              id="email"
                              v-validate="'email'" data-vv-as="email"
                              v-model="subject_expert.email"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <ValidationProvider name="area_type_id" vid="area_type_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="area_type_id "
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('org_pro.area_type')}} <span class="text-danger"> *</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="subject_expert.area_type_id"
                                id="area_type_id"
                                :options="getAreaTypeList"
                                @change="getAreaTypeData(subject_expert.area_type_id)"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12" v-if="ItemShow">
                        <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="division_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('org_pro_division.division')}} <span class="text-danger"> *</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="subject_expert.division_id"
                                :options="divisionList"
                                id="division_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="12" v-if="ItemShow">
                        <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="district_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('org_pro_district.district')}} <span class="text-danger"> *</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="subject_expert.district_id"
                                :options="districtList"
                                id="district_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12" v-if="PauroshobaItemShow || UnionItemShow">
                        <ValidationProvider name="Upazila" vid="upazila_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="upazila_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger"> *</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="subject_expert.upazilla_id"
                                :options="upazilaList"
                                id="upazila_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12" v-if="UnionItemShow">
                        <ValidationProvider name="Union" vid="union_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="union_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('org_pro_union.union')}} <span class="text-danger"> *</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="subject_expert.union_id"
                                :options="unionList"
                                id="union_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12" v-if="CityCorpItemShow">
                        <ValidationProvider name="City Corporation" vid="city_corporation_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="city_corporation_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{ $t('org_pro.city_corporation') }} <span class="text-danger"> *</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="subject_expert.city_corporation_id"
                                :options="cityCorporationList"
                                id="city_corporation_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12" v-if="PauroshobaItemShow">
                        <ValidationProvider name="Municipality" vid="pauroshoba_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="pauroshoba_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{ $t('org_pro.pauroshoba') }} <span class="text-danger"> *</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="subject_expert.pauroshoba_id"
                                :options="pauroshobaList"
                                id="pauroshoba_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12" v-if="ItemShow">
                        <ValidationProvider name="Ward" vid="ward_id" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="ward_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{ $t('org_pro.ward') }}
                                </template>
                                <b-form-select
                                plain
                                v-model="subject_expert.ward_id"
                                :options="wardList"
                                id="ward_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    </b-row>
                    <b-row class="bg-success text-white text-center mb-2">
                        <h3 class="text-center w-50 m-auto text-white">{{$t('research_manage.expertise_information')}}</h3>
                    </b-row>
                    <b-row class="bg-success text-white text-center mb-5">
                        <h4 class="text-center w-50 m-auto text-white">{{$t('research_manage.educational_background')}}</h4>
                    </b-row>
                    <b-row v-for="(detail,index) in subject_expert_education.education" :key="index" class="text-black mb-3">
                                 <b-col lg="6" sm="12">
                                    <ValidationProvider name="Name Of Degree(En)" vid="name_of_degree_en" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="name_of_degree_en"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{$t('research_manage.name_of_degree_en')}} <span class="text-danger"> *</span>
                                        </template>
                                        <b-form-input
                                        id="name_of_degree_en"
                                        v-model="detail.name_of_degree_en"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Name Of degree (Bn)" vid="name_of_degree_bn" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="name_of_degree_bn"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{$t('research_manage.name_of_degree_bn')}} <span class="text-danger"> *</span>
                                        </template>
                                        <b-form-input
                                        id="name_of_degree_bn"
                                        v-model="detail.name_of_degree_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Major in (En)" vid="major_in_en" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="major_in_en"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{$t('research_manage.major_in_en')}} <span class="text-danger"> *</span>
                                        </template>
                                        <b-form-input
                                        id="major_in_en"
                                        v-model="detail.major_in_en"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Major in (Bn)" vid="major_in_bn" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="major_in_bn"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{$t('research_manage.major_in_bn')}} <span class="text-danger"> *</span>
                                        </template>
                                        <b-form-input
                                        id="major_in_bn"
                                        v-model="detail.major_in_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                    <ValidationProvider name="Institution Name (En)" vid="institution_name_en" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="institution_name_en"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{$t('research_manage.institution_name_en')}} <span class="text-danger"> *</span>
                                        </template>
                                        <b-form-input
                                        id="institution_name_en"
                                        v-model="detail.institution_name_en"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                        <ValidationProvider name="Institution Name (Bn)" vid="institution_name_bn" rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="institution_name_bn"
                                            slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{$t('research_manage.institution_name_bn')}} <span class="text-danger"> *</span>
                                            </template>
                                            <b-form-input
                                            id="institution_name_bn"
                                            v-model="detail.institution_name_bn"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                        <ValidationProvider name="Passing Year" vid="passing_year" rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="passing_year"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{$t('research_manage.passing_year')}} <span class="text-danger"> *</span>
                                            </template>
                                            <b-form-select
                                            id="passing_year"
                                            v-model="detail.passing_year"
                                            :options="yearList"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            style="    border: 1px solid green;"
                                            >
                                            <template v-slot:first>
                                               <b-form-select-option disabled :value="''">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                </b-col>
                                <b-col lg="6" sm="12">
                                        <ValidationProvider name="Result" vid="result" rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="result"
                                            slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{$t('research_manage.result')}} <span class="text-danger"> *</span>
                                            </template>
                                            <b-form-input
                                            id="result"
                                            v-model="detail.result"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                </b-col>
                                <b-col xl="6" lg="6" sm="6">
                                </b-col>
                                <b-col xl="4" lg="4" sm="4">
                                </b-col>
                                <b-col xl="2" lg="2" sm="2">
                                <button @click="removetwo(index)" class="btn btn-sm btn-danger mr-3" type="button" v-show="index || ( !index && subject_expert_education.education.length > 1)"><i class="far fa-trash-alt" style="margin-right: 0px;"></i></button>
                                <button @click="addItem" size="sm" class="btn btn-sm btn-primary" type="button" v-show="index == subject_expert_education.education.length-1"><i class="ri-add-line m-0"></i></button>
                                </b-col>
                        </b-row>
                    <b-row class="bg-success text-white text-center mb-5">
                        <h4 class="text-center w-50 m-auto text-white">{{$t('research_manage.other_information')}}</h4>
                    </b-row>
                    <b-row v-for="(detail,index) in subject_expert_otherinfo.otherinfo" :key="index" class="text-black mb-3">
                                 <b-col lg="6" sm="12">
                                    <ValidationProvider name="Expertise Area(En)" vid="expertise_area_en" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="expertise_area_en"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{$t('research_manage.expertise_area_en')}} <span class="text-danger"> *</span>
                                        </template>
                                        <b-form-input
                                        id="expertise_area_en"
                                        v-model="detail.expertise_area_en"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                 <b-col lg="6" sm="12">
                                    <ValidationProvider name="Expertise Area(Bn)" vid="expertise_area_bn" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="expertise_area_bn"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{$t('research_manage.expertise_area_bn')}} <span class="text-danger"> *</span>
                                        </template>
                                        <b-form-input
                                        id="expertise_area_bn"
                                        v-model="detail.expertise_area_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xl="10" lg="10" sm="10">
                                </b-col>
                                <b-col xl="2" lg="2" sm="2">
                                <button @click="remove(index)" class="btn btn-sm btn-danger mr-3" type="button" v-show="index || ( !index && subject_expert_otherinfo.otherinfo.length > 1)"><i class="far fa-trash-alt" style="margin-right: 0px;"></i></button>
                                <button @click="addItemtwo" size="sm" class="btn btn-sm btn-primary" type="button" v-show="index == subject_expert_otherinfo.otherinfo.length-1"><i class="ri-add-line m-0"></i></button>
                                </b-col>
                    </b-row>
                    <b-row>
                    <b-col lg="6" sm="12">
                          <ValidationProvider name="Number Of publication" vid="number_of_publication" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="mobile"
                              slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('research_manage.number_of_publication')}}<span class="text-danger"> *</span>
                              </template>
                              <b-form-input
                              id="number_of_publication"
                              v-model="subject_expert.number_of_publication"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Title Of last Thesis (En)" vid="title_of_last_thesis_en" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="title_of_last_thesis_en"
                              slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('research_manage.title_of_last_thesis_en')}}<span class="text-danger"> *</span>
                              </template>
                              <b-form-input
                              id="title_of_last_thesis_en"
                              v-model="subject_expert.title_of_last_thesis_en"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                          <ValidationProvider name="Title Of Last Thesis (Bn)" vid="title_of_last_thesis_bn" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="title_of_last_thesis_bn"
                              slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('research_manage.title_of_last_thesis_bn')}}<span class="text-danger"> *</span>
                              </template>
                              <b-form-input
                              id="title_of_last_thesis_bn"
                              v-model="subject_expert.title_of_last_thesis_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Link" vid="link" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="link"
                              slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('research_manage.link')}}<span class="text-danger"> *</span>
                              </template>
                              <b-form-input
                              id="link"
                              v-model="subject_expert.link"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col md="6" lg="6" sm="12">
                            <ValidationProvider name="Signature" vid="signature" ref="signature" :rules="`${id ? '' : 'required'}`">
                            <b-form-group class="row" label-cols-sm="4" label-for="signature" slot-scope="{ valid, errors }">
                                <template v-slot:label>
                                {{$t('research_manage.attachment')}}<span class="text-danger"> *</span>
                                <!-- <v-slot v-if="main_form.signature !== null">
                                    <a :href="baseUrl + 'download-attachment?file=uploads/general-information/attachment/original/' + main_form.signature" title="Evaluation Attachment" class="mr-3"><i class="ri-download-cloud-fill"></i></a>
                                </v-slot> -->
                                </template>
                                <b-form-file
                                    id="signature"
                                    @change="onSignatureChange"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </b-form-file>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                       </b-col>
                    </b-row>
                    <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                    </div>
                </b-form>
              </ValidationObserver>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '../../../../../../config/api_config'
import { subjectExpertInfoStore, subjectExpertInfoUpdate } from '../../../api/routes'
import researchTestingModal from '@/mixins/research-testing-modal'
export default {
  name: 'FormLayout',
  props: ['id', 'educationData', 'otherinfoData'],
  mixins: [researchTestingModal],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
        saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
        districtList: [],
        formData: [],
        cityCorporationList: [],
        upazilaList: [],
        wardList: [],
        unionList: [],
        tmpSignature: '',
        pauroshobaList: [],
        PauroshobaItemShow: false,
        UnionItemShow: false,
        CityCorpItemShow: false,
        ItemShow: false,
        subject_expert: {
          value: require('../../../../../../assets/images/user/user-01.jpg'),
          signature: [],
          name_en: '',
          name_bn: '',
          designation_en: '',
          designation_bn: '',
          org_name_en: '',
          org_name_bn: '',
          mobile: '',
          area_type_id: 0,
          division_id: 0,
          district_id: 0,
          upazilla_id: 0,
          union_id: 0,
          city_corporation_id: 0,
          pauroshoba_id: 0,
          ward_id: 0,
          email: '',
          link: '',
          attachment: '',
          number_of_publication: '',
          title_of_last_thesis_en: '',
          title_of_last_thesis_bn: '',
          education: [
          {
            name_of_degree_en: '',
            name_of_degree_bn: '',
            major_in_en: '',
            major_in_bn: '',
            institution_name_en: '',
            institution_name_bn: '',
            passing_year: '',
            result: ''
          }
          ],
          otherinfo: [
          {
          expertise_area_en: '',
          expertise_area_bn: ''
          }
          ]
        },
        subject_expert_education: {
          education: [
          {
            name_of_degree_en: '',
            name_of_degree_bn: '',
            major_in_en: '',
            major_in_bn: '',
            institution_name_en: '',
            institution_name_bn: '',
            passing_year: '',
            result: ''
          }
          ]
        },
        subject_expert_otherinfo: {
          otherinfo: [
          {
          expertise_area_en: '',
          expertise_area_bn: ''
          }
          ]
        },
        subject_expert_otherinfo_extra: {
          otherinfo: [
          {
          expertise_area_en: '',
          expertise_area_bn: ''
          }
          ]
        }
    }
  },
  computed: {
    yearList: function () {
      var min = new Date().getFullYear()
      var max = min + 10
      var years = []
      for (var i = min; i <= max; i++) {
        if (this.$i18n.locale === 'bn') {
              years.push(this.$n(i, { useGrouping: false }))
          } else {
              years.push(i)
        }
      }
      return years
    },
    sectorList: function () {
        return this.$store.state.AgriResearch.commonObj.sectorList
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    divisionList: function () {
        return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    getAreaTypeList: function () {
            const objectData = this.$store.state.commonObj.wardTypeList
            return objectData.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.id, text: obj.name_bn }
                } else {
                    return { value: obj.id, text: obj.name }
                }
            })
        }
  },
  watch: {
    'subject_expert.upazilla_id': function (newVal, oldVal) {
    this.unionList = this.getUnionList(newVal)
    this.pauroshobaList = this.getPauroshobaList(newVal)
    },
    'subject_expert.district_id': function (newVal, oldVal) {
        this.cityCorporationList = this.getCityCorporationList(newVal)
        this.upazilaList = this.getUpazilaList(newVal)
    },
    'subject_expert.division_id': function (newVal, oldVal) {
        this.districtList = this.getDistrictList(newVal)
    },
    'subject_expert.city_corporation_id': function (newVal, oldVal) {
    this.wardList = this.getWardListByCityCorportaion(newVal)
    },
    'subject_expert.pauroshoba_id': function (newVal, oldVal) {
    this.wardList = this.getWardListByPauroshoba(newVal)
    },
    'subject_expert.union_id': function (newVal, oldVal) {
    this.wardList = this.getWardListByUnion(newVal)
    }
  },
   created () {
    this.month = [
      { value: 'january', text: 'January' },
      { value: 'february', text: 'February' },
      { value: 'march', text: 'March' },
      { value: 'april', text: 'April' },
      { value: 'may', text: 'May' },
      { value: 'june', text: 'June' },
      { value: 'july', text: 'July' },
      { value: 'august', text: 'August' },
      { value: 'september', text: 'September' },
      { value: 'october', text: 'October' },
      { value: 'novmber', text: 'Novmber' },
      { value: 'december', text: 'December' }
      ]
    if (this.id) {
        let tmp = this.getSubSectorData()
        // Object.freeze(tmp)
        this.subject_expert = tmp
        this.getAreaTypeData(this.subject_expert.area_type_id)
        tmp = this.getSubSectorData()
        this.subject_expert = tmp
        const education = this.educationData.filter(item => parseInt(item.subject_expert_id) === this.id)
        const otherinfo = this.otherinfoData.filter(item => parseInt(item.subject_expert_id) === this.id)
        if (education.length > 0) {
        this.subject_expert_education.education = []
        this.subject_expert_education.education.push(...education)
        } else {
        this.subject_expert_education.education = []
        this.addItem()
        }
        if (otherinfo.length > 0) {
        this.subject_expert_otherinfo.otherinfo = []
        this.subject_expert_otherinfo.otherinfo.push(...otherinfo)
        this.subject_expert_otherinfo_extra.otherinfo.push(...otherinfo)
        } else {
           this.subject_expert_otherinfo.otherinfo = []
           this.addItemtwo()
        }
    }
  },
  mounted () {
   if (this.id) {
    }
    core.index()
  },
  methods: {
    async onSignatureChange (e) {
      const { valid } = await this.$refs.signature.validate(e)
      if (!valid) return false
      this.tmpSignature = e.target.files[0]
      this.sig_src = URL.createObjectURL(e.target.files[0])
    },
    getPauroshobaList (upazillaId = null) {
        const objectData = this.$store.state.commonObj.pauroshobaList
        if (upazillaId) {
            return objectData.filter(item => item.upazilla_id === upazillaId && item.status === 0)
        }
        return objectData
    },
    getUnionList (upazilaId = null) {
        const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
        if (upazilaId) {
            return unionList.filter(union => union.upazilla_id === upazilaId)
        }
        return unionList
    },
    getWardListByCityCorportaion (cityCorpId) {
        return this.$store.state.commonObj.wardList.filter(item => item.city_corporation_id === cityCorpId && item.status === 0)
    },
    getWardListByPauroshoba (paroshobaId) {
        return this.$store.state.commonObj.wardList.filter(item => item.pauroshoba_id === parseInt(paroshobaId) && item.status === 0)
    },
    getWardListByUnion (unionId) {
        return this.$store.state.commonObj.wardList.filter(item => item.unionId === parseInt(unionId) && item.status === 0)
    },
    getCityCorporationList (districtId) {
        return this.$store.state.commonObj.cityCorporationList.filter(item => item.status === 0 && item.district_id === districtId)
    },
    getUpazilaList (districtId = null) {
            const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
            if (districtId) {
                return upazilaList.filter(upazila => upazila.district_id === districtId)
            }
            return upazilaList
    },
    getDistrictList (divisionId = null) {
        const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
        if (divisionId) {
            return districtList.filter(district => district.division_id === divisionId)
        }
        return districtList
    },
    getAreaTypeData (typeId) {
        this.ItemShow = true
        if (typeId === 1) {
            this.CityCorpItemShow = true
            this.PauroshobaItemShow = false
            this.UnionItemShow = false
        } else if (typeId === 2) {
            this.CityCorpItemShow = false
            this.PauroshobaItemShow = true
            this.UnionItemShow = false
        } else if (typeId === 3) {
            this.CityCorpItemShow = false
            this.PauroshobaItemShow = false
            this.UnionItemShow = true
        }
    },
    remove (key) {
        this.subject_expert_otherinfo.otherinfo.splice(key, 1)
        this.subject_expert_otherinfo_extra.otherinfo.splice(key, 1)
    },
    removetwo (key) {
        this.subject_expert_education.education.splice(key, 1)
    },
    addItem () {
      const obj = {
            name_of_degree_en: '',
            name_of_degree_bn: '',
            major_in_en: '',
            major_in_bn: '',
            institution_name_en: '',
            institution_name_bn: '',
            passing_year: '',
            result: ''
          }
      this.subject_expert_education.education.push(obj)
    },
    addItemtwo () {
      const obj = {
          expertise_area_en: '',
          expertise_area_bn: ''
      }
      this.subject_expert_otherinfo.otherinfo.push(obj)
      this.subject_expert_otherinfo_extra.otherinfo.push(obj)
    },
    getSubSectorData () {
      const tmpData = this.$store.state.list.find(project => project.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async saveData () {
      try {
      this.formData = new FormData()
      Object.keys(this.subject_expert).map(key => {
          if (key === 'education') {
          this.formData.append('educationn', JSON.stringify(this.subject_expert_education.education))
          }
          if (key === 'otherinfo') {
          this.formData.append('otherinfoo', JSON.stringify(this.subject_expert_otherinfo.otherinfo))
          }
          if (key === 'signature') {
                this.formData.append(key, this.tmpSignature)
          } else {
          this.formData.append(key, this.subject_expert[key])
        }
        })
        } catch (e) {
        }
      // for (var key of formData.entries()) {
      // }
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      if (this.id) {
        this.subject_expert.education = []
        this.subject_expert_otherinfo.otherinfo = []
        this.subject_expert.otherinfo = this.subject_expert_otherinfo.otherinfo
        this.subject_expert.education = this.subject_expert_education.education
        this.subject_expert_otherinfo_extra.otherinfo.splice(0, 1)
        // this.formData.append('_method', 'PUT')
          this.formData.append('signature', this.tmpSignature)
        //   this.formData.append('educationn', JSON.stringify(this.subject_expert.education))
          this.formData.append('educationn', JSON.stringify(this.subject_expert_education.education))
          this.formData.append('otherinfoo', JSON.stringify(this.subject_expert_otherinfo_extra.otherinfo))
        result = await RestApi.postData(agriResearchServiceBaseUrl, `${subjectExpertInfoUpdate}/${this.id}`, this.formData)
      } else {
        this.subject_expert.education = []
        this.subject_expert_otherinfo.otherinfo = []
        this.subject_expert.otherinfo = this.subject_expert_otherinfo.otherinfo
        this.subject_expert.education = this.subject_expert_education.education
        result = await RestApi.postData(agriResearchServiceBaseUrl, subjectExpertInfoStore, this.formData)
      }
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)
      if (result.success) {
        this.$store.dispatch('AgriResearch/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
        } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    }
  }
}
</script>
