<template>
  <b-container fluid>
        <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row class="details">
                <b-col lg="12" sm="12">
                    <b-row>
                        <b-col lg="12" sm="12">
                            <b-row>
                                <b-col lg="12" sm="12">
                                        <div style="font-size: 16px; background-color: rgb(51, 121, 130);"><h5 class="text-white text-center">{{$t('research_manage.general_info')}}</h5></div>
                                    <b-table-simple striped bordered small hover>
                                       <tbody>
                                            <b-tr>
                                                <b-td style="width: 50% !important;">{{ $t('research_manage.name_en') }}</b-td>
                                                <b-td style="width: 50% !important;">{{subject_expert.name_en}}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('research_manage.name_bn')}}</b-td>
                                                <b-td>{{subject_expert.name_bn}}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('research_manage.designation_en')}}</b-td>
                                                <b-td>{{subject_expert.designation_en}}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('research_manage.designation_bn')}}</b-td>
                                                <b-td>{{subject_expert.designation_bn}}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td style="width: 50% !important;">{{$t('research_manage.org_name_en')}}</b-td>
                                                <b-td style="width: 50% !important;">{{subject_expert.org_name_en}}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('research_manage.org_name_bn')}}</b-td>
                                                <b-td>{{subject_expert.org_name_bn}}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('research_manage.mobile')}}</b-td>
                                                <b-td>{{subject_expert.mobile}}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('research_manage.email')}}</b-td>
                                                <b-td>{{subject_expert.email}}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('research_manage.number_of_publication')}}</b-td>
                                                <b-td>{{subject_expert.number_of_publication}}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td style="width: 50% !important;">{{$t('research_manage.title_of_last_thesis_en')}}</b-td>
                                                <b-td style="width: 50% !important;">{{subject_expert.title_of_last_thesis_en}}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('research_manage.title_of_last_thesis_bn')}}</b-td>
                                                <b-td>{{subject_expert.title_of_last_thesis_bn}}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('research_manage.link')}}</b-td>
                                                <b-td>{{subject_expert.link}}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('research_manage.attachment')}}</b-td>
                                                <b-td>{{subject_expert.attachment}}</b-td>
                                            </b-tr>
                                       </tbody>
                                    </b-table-simple>
                                </b-col>
                                <b-col v-for="(detail,index) in subject_expert.education" :key="index" lg="12" sm="12">
                                        <div style="font-size: 16px; background-color: rgb(51, 121, 130);"><h5 class="text-white text-center">{{$t('research_manage.educational_background')}}</h5></div>
                                    <b-table-simple class="width-100" striped bordered small hover>
                                       <tbody>
                                            <b-tr>
                                                <b-td style="width: 50% !important;">{{$t('research_manage.name_of_degree_en')}}</b-td>
                                                <b-td style="width: 50% !important;">{{detail.name_of_degree_en}}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('research_manage.name_of_degree_bn')}}</b-td>
                                                <b-td>{{detail.name_of_degree_bn}}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('research_manage.major_in_en')}}</b-td>
                                                <b-td>{{detail.major_in_en}}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('research_manage.major_in_bn')}}</b-td>
                                                <b-td>{{detail.major_in_bn}}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td style="width: 50% !important;">{{$t('research_manage.institution_name_en')}}</b-td>
                                                <b-td style="width: 50% !important;">{{detail.institution_name_en}}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('research_manage.institution_name_bn')}}</b-td>
                                                <b-td>{{detail.institution_name_bn}}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('research_manage.passing_year')}}</b-td>
                                                <b-td>{{detail.passing_year}}</b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('research_manage.result')}}</b-td>
                                                <b-td>{{detail.result}}</b-td>
                                            </b-tr>
                                       </tbody>
                                    </b-table-simple>
                                </b-col>
                                <b-col v-for="(detail,index) in subject_expert.otherinfo" :key="index" lg="12" sm="12">
                                        <div style="font-size: 16px; background-color: rgb(51, 121, 130);"><h5 class="text-white text-center">{{$t('research_manage.other_information')}}</h5></div>
                                    <b-table-simple striped bordered small hover >
                                        <tbody>
                                        <b-tr>
                                            <b-td style="width: 50% !important;">{{$t('research_manage.expertise_area_en')}}</b-td>
                                                <b-td>{{detail.expertise_area_en}}</b-td>
                                        </b-tr>
                                        <b-tr>
                                            <b-td>{{$t('research_manage.expertise_area_bn')}}</b-td>
                                            <b-td>{{detail.expertise_area_bn}}</b-td>
                                        </b-tr>
                                        </tbody>
                                    </b-table-simple>
                                </b-col>
                            </b-row>
                            <div class="row">
                                <div class="col-sm-3"></div>
                                <div class="col text-right">
                                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-detail')">{{ $t('globalTrans.close') }}</b-button>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../../config/pluginInit'
import RestApi, { agriResearchServiceBaseUrl } from '../../../../../../config/api_config'
import { subjectExpertInfoStore, subjectExpertInfoUpdate } from '../../../api/routes'
import researchTestingModal from '@/mixins/research-testing-modal'
export default {
  name: 'FormLayout',
  props: ['id', 'educationData', 'otherinfoData'],
  mixins: [researchTestingModal],
  components: {
  },
  data () {
    return {
        org: [],
        saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
        districtList: [],
        cityCorporationList: [],
        upazilaList: [],
        wardList: [],
        unionList: [],
        tmpSignature: '',
        pauroshobaList: [],
        PauroshobaItemShow: false,
        UnionItemShow: false,
        CityCorpItemShow: false,
        ItemShow: false,
        subject_expert: {
        value: require('../../../../../../assets/images/user/user-01.jpg'),
          signature: [],
          name_en: '',
          district_id: '',
          division_id: '',
          name_bn: '',
          designation_en: '',
          designation_bn: '',
          org_name_en: '',
          org_name_bn: '',
          city_corporation_id: '',
          pauroshoba_id: '',
          union_id: '',
          mobile: '',
          email: '',
          link: '',
          attachment: '',
          number_of_publication: '',
          title_of_last_thesis_en: '',
          title_of_last_thesis_bn: '',
          education: [
          {
            name_of_degree_en: '',
            name_of_degree_bn: '',
            major_in_en: '',
            major_in_bn: '',
            institution_name_en: '',
            institution_name_bn: '',
            passing_year: '',
            result: ''
          }
          ],
          otherinfo: [
          {
          expertise_area_en: '',
          expertise_area_bn: ''
          }
          ]
        }
    }
  },
  computed: {
    sectorList: function () {
        return this.$store.state.AgriResearch.commonObj.sectorList
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    divisionList: function () {
        return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    getAreaTypeList: function () {
            const objectData = this.$store.state.commonObj.wardTypeList
            return objectData.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.id, text: obj.name_bn }
                } else {
                    return { value: obj.id, text: obj.name }
                }
            })
        }
  },
  watch: {
    'subject_expert.upazilla_id': function (newVal, oldVal) {
    this.unionList = this.getUnionList(newVal)
    this.pauroshobaList = this.getPauroshobaList(newVal)
    },
    'subject_expert.district_id': function (newVal, oldVal) {
            this.cityCorporationList = this.getCityCorporationList(newVal)
            this.upazilaList = this.getUpazilaList(newVal)
    },
    'subject_expert.division_id': function (newVal, oldVal) {
        this.districtList = this.getDistrictList(newVal)
    },
    'subject_expert.city_corporation_id': function (newVal, oldVal) {
    this.wardList = this.getWardListByCityCorportaion(newVal)
    },
    'subject_expert.pauroshoba_id': function (newVal, oldVal) {
    this.wardList = this.getWardListByPauroshoba(newVal)
    },
    'subject_expert.union_id': function (newVal, oldVal) {
    this.wardList = this.getWardListByUnion(newVal)
    }
  },
  created () {
    this.org = [
      { value: '2021', text: '2021' },
      { value: '2020', text: '2020' },
      { value: '2019', text: '2019' },
      { value: '2018', text: '2018' },
      { value: '2017', text: '2017' },
      { value: '2016', text: '2016' },
      { value: '2015', text: '2015' },
      { value: '2014', text: '2014' },
      { value: '2013', text: '2013' },
      { value: '2012', text: '2012' },
      { value: '2011', text: '2011' },
      { value: '2010', text: '2010' },
      { value: '2009', text: '2009' },
      { value: '2008', text: '2008' },
      { value: '2007', text: '2007' },
      { value: '2006', text: '2006' },
      { value: '2005', text: '2005' },
      { value: '2004', text: '2004' },
      { value: '2003', text: '2003' },
      { value: '2001', text: '2001' },
      { value: '2000', text: '2000' },
      { value: '1999', text: '1999' },
      { value: '1998', text: '1998' },
      { value: '1997', text: '1997' },
      { value: '1996', text: '1996' },
      { value: '1995', text: '1995' },
      { value: '1994', text: '1994' },
      { value: '1993', text: '1993' },
      { value: '1992', text: '1992' },
      { value: '1991', text: '1991' },
      { value: '1990', text: '1990' },
      { value: '1989', text: '1989' },
      { value: '1988', text: '1988' },
      { value: '1987', text: '1987' },
      { value: '1986', text: '1986' },
      { value: '1985', text: '1985' },
      { value: '1984', text: '1984' },
      { value: '1983', text: '1983' },
      { value: '1982', text: '1982' },
      { value: '1981', text: '1981' },
      { value: '1980', text: '1980' },
      { value: '1979', text: '1979' },
      { value: '1978', text: '1978' },
      { value: '1977', text: '1977' },
      { value: '1976', text: '1976' },
      { value: '1975', text: '1975' },
      { value: '1974', text: '1974' },
      { value: '1973', text: '1973' },
      { value: '1972', text: '1972' },
      { value: '1971', text: '1971' },
      { value: '1970', text: '1970' },
      { value: '1969', text: '1969' }
      ]
    this.month = [
      { value: 'january', text: 'January' },
      { value: 'february', text: 'February' },
      { value: 'march', text: 'March' },
      { value: 'april', text: 'April' },
      { value: 'may', text: 'May' },
      { value: 'june', text: 'June' },
      { value: 'july', text: 'July' },
      { value: 'august', text: 'August' },
      { value: 'september', text: 'September' },
      { value: 'october', text: 'October' },
      { value: 'novmber', text: 'Novmber' },
      { value: 'december', text: 'December' }
      ]
    if (this.id) {
        const tmp = this.getSubSectorData()
        // Object.freeze(tmp)
        this.subject_expert = tmp
    }
  },
  mounted () {
   if (this.id) {
        const tmp = this.getSubSectorData()
        this.subject_expert = tmp
        const education = this.educationData.filter(item => parseInt(item.subject_expert_id) === this.id)
        const otherinfo = this.otherinfoData.filter(item => parseInt(item.subject_expert_id) === this.id)
        if (education.length > 0) {
        this.subject_expert.education = []
        this.subject_expert.education.push(...education)
        } else {
        this.subject_expert.education = []
        this.addItem()
        }
        if (otherinfo.length > 0) {
        this.subject_expert.otherinfo = []
        this.subject_expert.otherinfo.push(...otherinfo)
        } else {
           this.subject_expert.otherinfo = []
           this.addItemtwo()
        }
    }
    core.index()
  },
  methods: {
    async onSignatureChange (e) {
      const { valid } = await this.$refs.signature.validate(e)
      if (!valid) return false

      this.tmpSignature = e.target.files[0]
      this.sig_src = URL.createObjectURL(e.target.files[0])
    },
    getPauroshobaList (upazillaId = null) {
        const objectData = this.$store.state.commonObj.pauroshobaList
        if (upazillaId) {
            return objectData.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text_en }
                }
            })
        }
    },
    getUnionList (upazilaId = null) {
        const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
        if (upazilaId) {
            return unionList.filter(union => union.upazilla_id === upazilaId)
        }
        return unionList
    },
    getWardListByCityCorportaion (cityCorpId) {
        const objectData = this.$store.state.commonObj.wardList
        const wardObjectList = objectData.filter(item => item.city_corporation_id === cityCorpId && item.status === 0)
        if (wardObjectList.length > 0) {
        return wardObjectList.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text }
            }
        })
        } else {
           wardObjectList.push({ value: 'null', text: 'null' })
           return wardObjectList.push({ value: 'null', text: 'null' })
        }
    },
    getCityCorporationList (districtId) {
        const objectData = this.$store.state.commonObj.cityCorporationList.filter(item => item.status === 0 && item.district_id === districtId)
        return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    getUpazilaList (districtId = null) {
            const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
            if (districtId) {
                return upazilaList.filter(upazila => upazila.district_id === districtId)
            }
            return upazilaList
    },
    getDistrictList (divisionId = null) {
        const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
        if (divisionId) {
            return districtList.filter(district => district.division_id === divisionId)
        }
        return districtList
    },
    getAreaTypeData (typeId) {
        this.ItemShow = true
        if (typeId === 1) {
            this.CityCorpItemShow = true
            this.PauroshobaItemShow = false
            this.UnionItemShow = false
        } else if (typeId === 2) {
            this.CityCorpItemShow = false
            this.PauroshobaItemShow = true
            this.UnionItemShow = false
        } else if (typeId === 3) {
            this.CityCorpItemShow = false
            this.PauroshobaItemShow = false
            this.UnionItemShow = true
        }
    },
    remove (key) {
        this.subject_expert.education.splice(key, 1)
    },
    removetwo (key) {
        this.subject_expert.otherinfo.splice(key, 1)
    },
    addItem () {
      const obj = {
            name_of_degree_en: '',
            name_of_degree_bn: '',
            major_in_en: '',
            major_in_bn: '',
            institution_name_en: '',
            institution_name_bn: '',
            passing_year: '',
            result: ''
          }
      // const key1 = parseInt(this.subject_expert.education.length - 1)
      // const item = this.formData.unit_list_details[key1]
      this.subject_expert.education.push(obj)
    },
    addItemtwo () {
      const obj = {
          expertise_area_en: '',
          expertise_area_bn: ''
      }
      // const key1 = parseInt(this.subject_expert.education.length - 1)
      // const item = this.formData.unit_list_details[key1]
      this.subject_expert.otherinfo.push(obj)
    },
    getSubSectorData () {
      const tmpData = this.$store.state.list.find(project => project.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async saveData () {
      try {
      var formData = new FormData()
      Object.keys(this.subject_expert).map(key => {
          if (key === 'education') {
          formData.append('educationn', JSON.stringify(this.subject_expert.education))
          }
          if (key === 'otherinfo') {
          formData.append('otherinfoo', JSON.stringify(this.subject_expert.otherinfo))
          }
        if (key === 'signature') {
            if (this.tmpSignature.name) {
                formData.append(key, this.tmpSignature)
            } else {
                formData.append(key, this.data[key])
            }
        } else {
          formData.append(key, this.subject_expert[key])
        }
      })
      } catch (e) {
      }
    // for (var key of formData.entries()) {
    // }
      formData.append('id', this.id)
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      if (this.id) {
        result = await RestApi.postData(agriResearchServiceBaseUrl, subjectExpertInfoUpdate, formData)
      } else {
        result = await RestApi.postData(agriResearchServiceBaseUrl, subjectExpertInfoStore, formData)
      }
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('AgriResearch/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    }
  }
}
</script>
