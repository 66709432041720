
<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('research_manage.expert_name') + ' ' + $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
            <b-col lg="6" md="6" sm="12" xs="12">
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('research_manage.expert_name')"
                    label-for="name"
                >
                    <b-form-input
                        id="name"
                        v-model="search.name"
                        placeholder=""
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col lg="6" md="6" sm="12" xs="12">
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('globalTrans.designation')"
                    label-for="name"
                >
                    <b-form-input
                        id="name"
                        v-model="search.designation"
                        placeholder=""
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col lg="6" md="6" sm="12" xs="12">
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('research_manage.org_name')"
                    label-for="name"
                >
                <b-form-input
                    id="name"
                    v-model="search.org_name"
                    placeholder=""
                ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col lg="6" md="6" sm="12" xs="12">
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('research_manage.expertise_area')"
                    label-for="name"
                >
                    <b-form-input
                        id="name"
                        v-model="search.expertise_area"
                        placeholder=""
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col lg="6" md="6" sm="12" xs="12">
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('research_manage.number_of_publication')"
                    label-for="name"
                >
                    <b-form-input
                        id="name"
                        v-model="search.number_of_publication"
                        placeholder=""
                    ></b-form-input>
                </b-form-group>
            </b-col>
            <b-col lg="2" md="2" sm="12" xs="12">
                <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
            </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('research_manage.expert_name') + ' ' + $t('globalTrans.list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
              <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="bg-primary"  bordered hover :items="datas" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(lab_no)="data">
                      <span class="capitalize">{{ data.item.lab_no }}</span>
                    </template>
                    <template v-slot:cell(org)="data">
                      <span class="capitalize">{{  orgList.find(e=>e.value===data.item.org_id).text }}</span>
                    </template>
                    <template v-slot:cell(project)="data">
                      <span class="capitalize">{{ projectList.find(e=>e.value===data.item.project_info_id) ?  projectList.find(e=>e.value===data.item.project_info_id).text : 'N / A'}}</span>
                    </template>
                    <template v-slot:cell(sector)="data">
                      <span class="capitalize">{{ sectorList.find(e=>e.value===data.item.sector_id) ? sectorList.find(e=>e.value===data.item.sector_id).text : 'N / A' }}</span>
                    </template>
                    <template v-slot:cell(sub_sector_name)="data">
                      {{ data.item.sub_sector_name }}
                    </template>
                    <template v-slot:cell(sub_sector_name_bn)="data">
                      {{ data.item.sub_sector_name_bn }}
                    </template>
                    <template v-slot:cell(status)="data">
                      <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                      <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button class="mr-1" title="View Details" v-b-modal.modal-detail variant=" iq-bg-success mr-1" size="sm" @click="edit(data.item)"><i class="ri-eye-line m-0 "></i></b-button>
                      <b-button title="Edit" v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                      <b-button :variant="data.item.status === 1 ? ' iq-bg-success border' : '  iq-bg-danger border'" size="sm" @click="remove(data.item)">
                        <i class="fas" :class="data.item.status === 1 ? 'fa-toggle-on' : 'fa-toggle-off'"></i>
                      </b-button>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :proposalList="proposalData" :educationData="EducationData" :otherinfoData="OtherInfoData" :key="editItemId"/>
      </p>
    </b-modal>
    <b-modal id="modal-detail" size="xl" :title="$t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <FormK :id="editItemId" :proposalList="proposalData" :educationData="EducationData" :otherinfoData="OtherInfoData" :key="editItemId"/>
    </b-modal>
  </b-container>
</template>
<script>
import FormV from './Form.vue'
import FormK from './FormTwo.vue'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { subjectExpertInfoList, subjectExpertInfoToggleStatus, proposalInvitationList, CommitteeTypeSetupInfoList } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { mapGetters } from 'vuex'
import { core } from '@/config/pluginInit'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    FormV, FormK
  },
  data () {
    return {
        itemDetail: {},
        datas: [],
        RoleData: [],
        committeeTypeData: [],
        proposalData: [],
        EducationData: [],
        OtherInfoData: [],
        search: {
            name: '',
            org_name: '',
            expertise_area: '',
            number_of_publication: '',
            designation: ''
        },
        sectorByProjectList: []
    }
  },
  watch: {
  },
  computed: {
      orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      ...mapGetters({
          authUser: 'Auth/authUser'
      }),
      projectList: function () {
          return this.$store.state.AgriResearch.commonObj.projectList.map(project => {
                  return { value: project.value, text: this.$i18n.locale === 'en' ? project.text_en : project.text_bn }
              })
      },
      sectorList: function () {
        return this.$store.state.AgriResearch.commonObj.sectorList
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('research_manage.subject_expert_info_entry') : this.$t('research_manage.subject_expert_info_update')
      },
      columns () {
          const labels = [
              { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
              { label: this.$t('globalTrans.name'), class: 'text-left' },
              { label: this.$t('globalTrans.designation'), class: 'text-left' },
              { label: this.$t('research_manage.org_name'), class: 'text-left' },
              { label: this.$t('research_manage.expertise_area'), class: 'text-left' },
              { label: this.$t('research_manage.number_of_publication'), class: 'text-left' },
              { label: this.$t('globalTrans.status'), class: 'text-center' },
              { label: this.$t('globalTrans.action'), class: 'text-center' }
          ]

          let keys = []

          if (this.$i18n.locale === 'bn') {
              keys = [
                  { key: 'index' },
                  { key: 'name_bn' },
                  { key: 'designation_bn' },
                  { key: 'org_name_bn' },
                  { key: 'expertise_area_bn' },
                  { key: 'number_of_publication' },
                  { key: 'status' },
                  { key: 'action' }
              ]
          } else {
              keys = [
                  { key: 'index' },
                  { key: 'name_en' },
                  { key: 'designation_en' },
                  { key: 'org_name_en' },
                  { key: 'expertise_area_en' },
                  { key: 'number_of_publication' },
                  { key: 'status' },
                  { key: 'action' }
              ]
          }
          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
      }
  },
  mounted () {
    core.index()
    if (this.authUser.role_id === 0) {
      this.search = Object.assign({}, this.search)
    }
    this.loadData()
    this.loadProposalData()
    this.loadCommitteeTypeData()
  },
  methods: {
    details (item) {
      this.itemDetail = item
    },
    async searchData () {
      this.loadData()
    },
    getSectorList () {
        const sectorList = this.$store.state.AgriResearch.commonObj.sectorList.filter(el => el.status === 0 && el.project_id === this.search.project_info_id)
        this.sectorByProjectList = sectorList.map(subProject => {
            return { value: subProject.value, text: this.$i18n.locale === 'en' ? subProject.text_en : subProject.text_bn }
          })
    },
    getResearchType (data) {
       return data.map(d => {
          return this.options.find((e) => e.value === parseInt(d)).text
        }).toString()
    },
    remove (item) {
      this.changeStatus(agriResearchServiceBaseUrl, subjectExpertInfoToggleStatus, item)
    },
    loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(agriResearchServiceBaseUrl, subjectExpertInfoList, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', response.data.data)
            this.paginationData(response.data)
            // const allData = this.$store.state.list
            this.EducationData = response.education
            this.OtherInfoData = response.otherinfo
            this.datas = response.data.data
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    loadProposalData () {
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        RestApi.getData(agriResearchServiceBaseUrl, proposalInvitationList, params).then(response => {
          if (response.success) {
            this.proposalData = response.data.data
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
    },
    loadCommitteeTypeData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      RestApi.getData(agriResearchServiceBaseUrl, CommitteeTypeSetupInfoList, params).then(response => {
          if (response.success) {
            this.committeeTypeData = response.data.data
          }
      })
    },
    dataList (data) {
      const listData = data
      return listData
    }
  }
}
</script>
<style scoped>
.fa-toggle-on {
  color: green;
}
</style>
